import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-user-level-profile-picture',
    standalone: true,
    imports: [],
    templateUrl: './user-level-profile-picture.component.html',
    styleUrl: './user-level-profile-picture.component.scss',
})
export class UserLevelProfilePictureComponent {
    @Input() level!: number;
    @Input() photo!: string;
}
