import * as i0 from '@angular/core';
import { InjectionToken, inject, makeEnvironmentProviders, numberAttribute, booleanAttribute, Component, ChangeDetectionStrategy, HostBinding, Input, Optional, SkipSelf, Injector, ElementRef, runInInjectionContext, NgModule, Inject } from '@angular/core';
import { isObservable, firstValueFrom } from 'rxjs';
const NgGlyphConfigToken = new InjectionToken('Ng Glyph Config');
const defaultConfig$1 = {
  size: '1em',
  opticalSize: 20,
  weight: 400,
  grade: 0,
  fill: false
};
/**
 * Provide the configuration for the glyph
 * @param config The configuration to use
 */
function provideNgGlyphsConfig(config) {
  return {
    provide: NgGlyphConfigToken,
    useValue: {
      ...defaultConfig$1,
      ...config
    }
  };
}
/**
 * Inject the configuration for the glyphs
 * @returns The configuration to use
 * @internal
 */
function injectNgGlyphsConfig() {
  return inject(NgGlyphConfigToken, {
    optional: true
  }) ?? defaultConfig$1;
}
const NgGlyphsToken = new InjectionToken('NgGlyphsToken');
function provideNgGlyphs(...glyphsets) {
  // if there are no glyphsets, throw an error
  if (!glyphsets.length) {
    throw new Error('Please provide at least one glyphset.');
  }
  // the default glyphset is the first one
  const defaultGlyphset = glyphsets[0].name;
  return makeEnvironmentProviders([{
    provide: NgGlyphsToken,
    useValue: {
      defaultGlyphset,
      glyphsets
    }
  }]);
}
function injectNgGlyphs() {
  const glyphs = inject(NgGlyphsToken, {
    optional: true
  });
  if (!glyphs) {
    throw new Error('Please provide the glyphs using the provideNgGlyphs() function.');
  }
  return glyphs;
}
function coerceCssPixelValue(value) {
  return value == null ? '' : /^\d+$/.test(value) ? `${value}px` : value;
}
class NgGlyph {
  constructor() {
    /**
     * Access the available glyphsets
     */
    this.glyphsets = injectNgGlyphs();
    /**
     * Access the default configuration
     */
    this.config = injectNgGlyphsConfig();
    /**
     * Define the glyphset to use
     */
    this.glyphset = this.glyphsets.defaultGlyphset;
    /**
     * Define the optical size of the glyph
     */
    this.opticalSize = this.config.opticalSize;
    /**
     * Define the weight of the glyph
     */
    this.weight = this.config.weight;
    /**
     * Define the grade of the glyph
     */
    this.grade = this.config.grade;
    /**
     * Define the fill of the glyph
     */
    this.fill = this.config.fill;
    /**
     * Define the size of the glyph
     */
    this.size = this.config.size;
    /**
     * Define the color of the glyph
     */
    this.color = this.config.color;
  }
  /**
   * Derive the glyphset class from the glyphset name
   */
  get glyphsetClass() {
    const glyphset = this.glyphsets.glyphsets.find(glyphset => glyphset.name === this.glyphset);
    if (!glyphset) {
      throw new Error(`The glyphset "${this.glyphset}" does not exist. Please provide a valid glyphset.`);
    }
    return glyphset.baseClass;
  }
  /**
   * Define the font variation settings of the glyph
   */
  get fontVariationSettings() {
    return `'FILL' ${this.fill ? 1 : 0}, 'wght' ${this.weight}, 'GRAD' ${this.grade}, 'opsz' ${this.opticalSize}`;
  }
  static {
    this.ɵfac = function NgGlyph_Factory(t) {
      return new (t || NgGlyph)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgGlyph,
      selectors: [["ng-glyph"]],
      hostVars: 9,
      hostBindings: function NgGlyph_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("textContent", ctx.name);
          i0.ɵɵclassMap(ctx.glyphsetClass);
          i0.ɵɵstyleProp("--ng-glyph__size", ctx.size)("color", ctx.color)("font-variation-settings", ctx.fontVariationSettings);
        }
      },
      inputs: {
        name: "name",
        glyphset: "glyphset",
        opticalSize: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "opticalSize", "opticalSize", numberAttribute],
        weight: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "weight", "weight", numberAttribute],
        grade: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "grade", "grade", numberAttribute],
        fill: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "fill", "fill", booleanAttribute],
        size: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "size", "size", coerceCssPixelValue],
        color: "color"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function NgGlyph_Template(rf, ctx) {},
      styles: ["[_nghost-%COMP%]{display:inline-block;width:var(--ng-glyph__size);height:var(--ng-glyph__size);font-size:var(--ng-glyph__size);overflow:hidden}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgGlyph, [{
    type: Component,
    args: [{
      selector: 'ng-glyph',
      standalone: true,
      template: ``,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:inline-block;width:var(--ng-glyph__size);height:var(--ng-glyph__size);font-size:var(--ng-glyph__size);overflow:hidden}\n"]
    }]
  }], null, {
    name: [{
      type: HostBinding,
      args: ['textContent']
    }, {
      type: Input,
      args: [{
        required: true
      }]
    }],
    glyphset: [{
      type: Input
    }],
    opticalSize: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    weight: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    grade: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    fill: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    size: [{
      type: HostBinding,
      args: ['style.--ng-glyph__size']
    }, {
      type: Input,
      args: [{
        transform: coerceCssPixelValue
      }]
    }],
    color: [{
      type: HostBinding,
      args: ['style.color']
    }, {
      type: Input
    }],
    glyphsetClass: [{
      type: HostBinding,
      args: ['class']
    }],
    fontVariationSettings: [{
      type: HostBinding,
      args: ['style.font-variation-settings']
    }]
  });
})();
const NgIconConfigToken = new InjectionToken('Ng Icon Config');
const defaultConfig = {
  size: '1em'
};
/**
 * Provide the configuration for the icons
 * @param config The configuration to use
 */
function provideNgIconsConfig(config) {
  return {
    provide: NgIconConfigToken,
    useValue: {
      ...defaultConfig,
      ...config
    }
  };
}
/**
 * Inject the configuration for the icons
 * @returns The configuration to use
 * @internal
 */
function injectNgIconConfig() {
  return inject(NgIconConfigToken, {
    optional: true
  }) ?? defaultConfig;
}
const NgIconLoaderToken = new InjectionToken('Ng Icon Loader Token');
/**
 * Helper function to create an object that represents a Loader feature.
 */
function loaderFeature(kind, providers) {
  return {
    kind: kind,
    providers: providers
  };
}
const NgIconCacheToken = new InjectionToken('Ng Icon Cache Token');
/**
 * Add caching to the loader. This will prevent the loader from being called multiple times for the same icon name.
 */
function withCaching() {
  return loaderFeature(0 /* NgIconLoaderFeatureKind.CachingFeature */, [{
    provide: NgIconCacheToken,
    useValue: new Map()
  }]);
}
/**
 * Provide a function that will return the SVG content for a given icon name.
 * @param loader The function that will return the SVG content for a given icon name.
 * @param features The list of features to apply to the loader.
 * @returns The SVG content for a given icon name.
 */
function provideNgIconLoader(loader, ...features) {
  return [{
    provide: NgIconLoaderToken,
    useValue: loader
  }, features.map(feature => feature.providers)];
}
/**
 * Inject the function that will return the SVG content for a given icon name.
 */
function injectNgIconLoader() {
  return inject(NgIconLoaderToken, {
    optional: true
  });
}
/**
 * Inject the cache that will store the SVG content for a given icon name.
 */
function injectNgIconLoaderCache() {
  return inject(NgIconCacheToken, {
    optional: true
  });
}

/**
 * Define the icons to use
 * @param icons The icons to provide
 */
function provideIcons(icons) {
  return [{
    provide: NgIconsToken,
    useFactory: parentIcons => ({
      ...parentIcons?.reduce((acc, icons) => ({
        ...acc,
        ...icons
      }), {}),
      ...icons
    }),
    deps: [[NgIconsToken, new Optional(), new SkipSelf()]],
    multi: true
  }];
}
const NgIconsToken = new InjectionToken('Icons Token');
/**
 * Inject the icons to use
 * @returns The icons to use
 * @internal
 */
function injectNgIcons() {
  return inject(NgIconsToken, {
    optional: true
  }) ?? [];
}

/**
 * A loader may return a promise, an observable or a string. This function will coerce the result into a promise.
 * @returns
 */
function coerceLoaderResult(result) {
  if (typeof result === 'string') {
    return Promise.resolve(result);
  }
  if (isObservable(result)) {
    return firstValueFrom(result);
  }
  return result;
}

/**
 * Hyphenated to lowerCamelCase
 */
function toPropertyName(str) {
  return str.replace(/([^a-zA-Z0-9])+(.)?/g, (_, __, chr) => chr ? chr.toUpperCase() : '').replace(/[^a-zA-Z\d]/g, '').replace(/^([A-Z])/, m => m.toLowerCase());
}
class NgIcon {
  constructor() {
    /** Access the global icon config */
    this.config = injectNgIconConfig();
    /** Access the icons */
    this.icons = injectNgIcons();
    /** Access the icon loader if defined */
    this.loader = injectNgIconLoader();
    /** Access the icon cache if defined */
    this.cache = injectNgIconLoaderCache();
    /** Access the injector */
    this.injector = inject(Injector);
    /** Access the element ref */
    this.elementRef = inject(ElementRef);
    /** Define the size of the icon */
    this.size = this.config.size;
    /** Define the color of the icon */
    this.color = this.config.color;
  }
  /** Define the name of the icon to display */
  set name(name) {
    this.setIcon(name);
  }
  /** Define the svg of the icon to display */
  set svg(svg) {
    this.elementRef.nativeElement.innerHTML = svg;
  }
  ngOnChanges(changes) {
    if (changes.size) {
      this.setIconSize();
    }
    if (changes.color) {
      this.setIconColor();
    }
    if (changes.strokeWidth) {
      this.setIconStrokeWidth();
    }
  }
  ngOnInit() {
    this.setIconStyles();
  }
  setIconColor() {
    this.elementRef.nativeElement.style.removeProperty('color');
    if (this.color !== undefined) {
      this.elementRef.nativeElement.style.setProperty('color', this.color);
    }
  }
  setIconSize() {
    this.elementRef.nativeElement.style.removeProperty('--ng-icon__size');
    if (this.size !== undefined) {
      this.elementRef.nativeElement.style.setProperty('--ng-icon__size', this.size.toString());
    }
  }
  setIconStrokeWidth() {
    this.elementRef.nativeElement.style.removeProperty('--ng-icon__stroke-width');
    if (this.strokeWidth !== undefined) {
      this.elementRef.nativeElement.style.setProperty('--ng-icon__stroke-width', this.strokeWidth.toString());
    }
  }
  /**
   * Set the styles for the icon. We use the style property to set the styles
   * rather than the host binding as it works with CSP.
   */
  setIconStyles() {
    this.setIconColor();
    this.setIconSize();
    this.setIconStrokeWidth();
  }
  /**
   * Load the icon with the given name and insert it into the template.
   * @param name The name of the icon to load.
   */
  async setIcon(name) {
    const propertyName = toPropertyName(name);
    for (const icons of [...this.icons].reverse()) {
      if (icons[propertyName]) {
        // insert the SVG into the template
        this.elementRef.nativeElement.innerHTML = icons[propertyName];
        return;
      }
    }
    // if there is a loader defined, use it to load the icon
    if (this.loader) {
      const result = await this.requestIconFromLoader(name);
      // if the result is a string, insert the SVG into the template
      if (result !== null) {
        this.elementRef.nativeElement.innerHTML = result;
        return;
      }
    }
    // if there is no icon with this name warn the user as they probably forgot to import it
    console.warn(`No icon named ${name} was found. You may need to import it using the withIcons function.`);
  }
  /**
   * Request the icon from the loader.
   * @param name The name of the icon to load.
   * @returns The SVG content for a given icon name.
   */
  requestIconFromLoader(name) {
    return new Promise(resolve => {
      runInInjectionContext(this.injector, async () => {
        // if we have a cache, check if the icon is already loaded (i.e, it is a string)
        if (this.cache) {
          const cachedResult = this.cache.get(name);
          if (typeof cachedResult === 'string') {
            resolve(cachedResult);
            return;
          }
          // it may be a promise, so we need to await it
          if (cachedResult instanceof Promise) {
            const result = await cachedResult;
            resolve(result);
            return;
          }
        }
        const promise = coerceLoaderResult(this.loader(name));
        // store the promise in the cache so if we get repeated calls (e.g. in a loop) before the loader has resolved
        // then don't call the loader function multiple times
        this.cache?.set(name, promise);
        // await the result of the promise
        const result = await promise;
        // if we have a cache, store the result
        this.cache?.set(name, result);
        resolve(result);
      });
    });
  }
  static {
    this.ɵfac = function NgIcon_Factory(t) {
      return new (t || NgIcon)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgIcon,
      selectors: [["ng-icon"]],
      inputs: {
        name: "name",
        svg: "svg",
        size: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "size", "size", coerceCssPixelValue],
        strokeWidth: "strokeWidth",
        color: "color"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function NgIcon_Template(rf, ctx) {},
      styles: ["[_nghost-%COMP%]{display:inline-block;width:var(--ng-icon__size);height:var(--ng-icon__size);overflow:hidden}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgIcon, [{
    type: Component,
    args: [{
      selector: 'ng-icon',
      template: '',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:inline-block;width:var(--ng-icon__size);height:var(--ng-icon__size);overflow:hidden}\n"]
    }]
  }], null, {
    name: [{
      type: Input
    }],
    svg: [{
      type: Input
    }],
    size: [{
      type: Input,
      args: [{
        transform: coerceCssPixelValue
      }]
    }],
    strokeWidth: [{
      type: Input
    }],
    color: [{
      type: Input
    }]
  });
})();
class NgIconsModule {
  constructor(icons) {
    if (Object.keys(icons).length === 0) {
      throw new Error('No icons have been provided. Ensure to include some icons by importing them using NgIconsModule.withIcons({ ... }).');
    }
  }
  /**
   * Define the icons that will be included in the application. This allows unused icons to
   * be tree-shaken away to reduce bundle size
   * @param icons The object containing the required icons
   */
  static withIcons(icons) {
    return {
      ngModule: NgIconsModule,
      providers: provideIcons(icons)
    };
  }
  static {
    this.ɵfac = function NgIconsModule_Factory(t) {
      return new (t || NgIconsModule)(i0.ɵɵinject(NgIconsToken));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgIconsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgIconsModule, [{
    type: NgModule,
    args: [{
      imports: [NgIcon],
      exports: [NgIcon]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NgIconsToken]
    }]
  }], null);
})();
const NG_ICON_DIRECTIVES = [NgIcon];

/**
 * Generated bundle index. Do not edit.
 */

export { NG_ICON_DIRECTIVES, NgGlyph, NgGlyphConfigToken, NgIcon, NgIconCacheToken, NgIcon as NgIconComponent, NgIconConfigToken, NgIconLoaderToken, NgIconsModule, NgIconsToken, injectNgGlyphsConfig, injectNgIconConfig, injectNgIconLoader, injectNgIconLoaderCache, injectNgIcons, provideIcons, provideNgGlyphs, provideNgGlyphsConfig, provideNgIconLoader, provideNgIconsConfig, withCaching };
