import {Component, Input, OnInit} from '@angular/core';
import levelConverter from '@shared/components/user/user-level-badge/levelConverter';

@Component({
    selector: 'app-user-level-badge',
    standalone: true,
    imports: [],
    templateUrl: './user-level-badge.component.html',
    styleUrl: './user-level-badge.component.scss',
})
export class UserLevelBadgeComponent implements OnInit {
    @Input() level: number = 1;
    badge: string = 'bronze';

    pathPrefix: string = 'assets/images/shared/user/level-badges/';

    ngOnInit() {
        this.badge = levelConverter(this.level);
        this.pathPrefix += this.badge + '.svg';
    }
}
